import * as $ from 'jquery';

$(function () {
    $("#toggle_checkbox").prop("checked", true).trigger("change");

    $("#toggle_checkbox").on("change", function () {
        const baseUrl = "/users/auth/google_oauth2";

        if ($(this).prop("checked")) {
            $("a.sso-google-link").attr("href", `${baseUrl}_sdc`);
            $("#sso_google_label").text("Sign in with SDC Google");
        } else {
            $("a.sso-google-link").attr("href", `${baseUrl}_loudcloud`);
            $("#sso_google_label").text("Sign in with Loudcloud Google");
        }
    });

    $(".sso-btn").removeClass("disabled");
});