import Choices from "choices.js";
import * as $ from "jquery";
import "bootstrap";
import moment from "moment";

document.addEventListener("DOMContentLoaded", (event) => {
    const FILTER_DATE_REGEX = /^(january|february|march|april|may|june|july|august|september|october|november|december)\s+\d{1,2}(\d{0})?,\s+\d{4}$/;
    let queryParams = new URLSearchParams(window.location.search);
    const dateToday = moment(new Date());
    const $sortFilter = $("#js-workspace-sort");
    const $onChangeTriggerFilter = $(".js-workspace-onchange-filter");
    const $itemsPerPage = $("#js-workspace-items-per-page");
    const $workSpaceTab = $(".js-workspace-tab");
    const $workspaceFilterForm = $("#js-worksplace-filter-form");
    const $workspaceClearFilter = $("#js-workspace-clear-filter");
    const $workspaceFilterCount = $("#js-workspace-filter-count");
    const $workspaceDateRangeFilters = $(".js-workspace-daterange-filters");
    const $workspaceSelect2 = $(".js-workspace-select2");
    const $workspaceSelectAllCheckbox = $(".js-workspace-checkbox.js-select-all");
    const $caseCheckboxes = $(".js-workspace-checkbox.js-select-individual");
    const $bulkAssignButton = $("#js-workspace-bulk-assign-button");
    const $bulkGenerateButton = $("#js-workspace-bulk-generate-button");
    const $bulkBuiltInNextButton = $("#js-workspace-bulk-built-in-next-button");
    const $bulkUpdateStatusButton = $("#js-workspace-bulk-update-status");
    const bulkGenerateModal = document.getElementById("bulk-generate-modal");
    const bulkBuiltInNextModal = document.getElementById("built-in-next-modal");
    const bulkUpdateStatusModal = document.getElementById("bulk-update-status-modal");
    const $bulkAssignToAgent = $("#js-bulk-assign-agent").children();
    const $bulkAssignToTeam = $("#js-bulk-assign-team").children();
    const $bulkAssignToQc = $("#js-bulk-assign-qc").children();
    const $bulkAssignRadios = $("input[name=assign_to]");

    const FILTER_KEYS = [
        "client_id[]",
        "due_date",
        "order_date",
        "start_date",
        "qc_start_date",
        "submit_date",
        "complete_date",
        "agent_id[]",
        "assigned_qc_id[]",
        "assigned_aqcm_id[]",
        "pdf_layout[]",
        "product_id[]",
        "team_id[]",
        "order_type[]",
        "time_zone",
        "filter_cases_already_failed",
        "cases_assigned_to_me",
        "training_cases",
        "test_cases",
        "rush_report",
        "first_order",
        "built_in_next",
        "needs_admin_approval",
        "impact_score",
        "impact_score_cat",
        "screenshot_count",
        "screenshot_count_cat",
        "network_count",
        "network_count_cat",
        "training_cases_only",
        "test_cases_only",
        "status[]",
        "completed_by[]",
        "send_in_next",
        "approved_by[]",
        "proofed_by[]",
    ];
    setFilterCount();
    initializeBulkAssignSelect();

    if (Boolean($bulkAssignRadios.length)) {
        $bulkAssignRadios.on("change", function (){
            let selected = $(this).val();
            $("#js-bulk-assign-agent").empty();
            $("#js-bulk-assign-team").empty();
            $("#js-bulk-assign-qc").empty();

            if (selected === "agent") {
                $("#js-bulk-assign-agent").append($bulkAssignToAgent);
            }
            if (selected === "team") {
                $("#js-bulk-assign-team").append($bulkAssignToTeam)
            }
            if (selected === "qc") {
                $("#js-bulk-assign-qc").append($bulkAssignToQc)
            }
            initializeBulkAssignSelect(); // re-initialize select2
        });
        $("#js-bulk-assign-team").empty(); // hide team selection on load
        $("#js-bulk-assign-qc").empty(); // hide qc selection on load
    }

    if (Boolean($workspaceDateRangeFilters.length)) {
        $workspaceDateRangeFilters.each((idx, el) => {
            let filterKey = el.getAttribute("name");
            let defaulValue = queryParams.get(filterKey);
            let maxDate = filterKey.includes("due") ? null : moment(dateToday);

            $(el).daterangepicker({
                opens: "left",
                locale: {
                    format: "MMMM DD, YYYY",
                    cancelLabel: "Clear"
                },
                startDate: defaulValue || moment(dateToday),
                autoUpdateInput: !!defaulValue,
                singleDatePicker: true,
                autoApply: false,
                alwaysShowCalendars: true,
                maxDate: maxDate,
            })

            $(el).on("apply.daterangepicker", function(ev, picker) {
                let $input = $(this);
                let dateText = picker.startDate.format("MMMM DD, YYYY")
                $input.val(dateText);
                if ($input.hasClass("js-from")) validateFilterDateFrom(dateText, $input);
                if ($input.hasClass("js-to")) validateFilterDateTo(dateText, $input);
            });

            $(el).on("cancel.daterangepicker", function() {
                $(this).val("");
            });
        });
    }

    if (Boolean($workspaceSelect2.length)) {
        $workspaceSelect2.each((idx, el) => {
            new Choices(el, {
                allowHTML: true,
                removeItemButton: true,
                paste: false,
            })
        })
    }

    function initializeBulkAssignSelect() {
        $(".js-workspace-bulk-assign-select").select2({
            dropdownParent: $("#bulk-assign-modal"),
            allowClear: true,
            width: "100%",
        });
    }

    if ($workspaceFilterForm) {
        $workspaceFilterForm.on("submit", handleFilterFormSubmit);
    }

    if ($workspaceClearFilter) {
        $workspaceClearFilter.on("click", handleFilterClear);
    }

    if ($sortFilter) {
        $sortFilter.on("change", handleSortChange);
    }

    if ($onChangeTriggerFilter) {
        $onChangeTriggerFilter.on("change", handleOnChangeFilter)
    }

    if ($itemsPerPage) {
        $itemsPerPage.on("change", handleItemsPerPageChange)
    }

    if ($workSpaceTab) {
        $workSpaceTab.on("click", cleanUp);
    }

    function handleOnChangeFilter(e) {
        const paramName = e.target.getAttribute("name");
        queryParams.delete(paramName);
        if (e.target.value) queryParams.append(paramName, e.target.value);
        filter();
    }

    function cleanUp(){
        localStorage.removeItem("checkboxValue");
    }

    function handleItemsPerPageChange(e) {
        e.preventDefault();
        queryParams.set("per_page", $itemsPerPage.val());
        filter();
    }

    function handleSortChange(e) {
        e.preventDefault();
        queryParams.set("sort_order", $sortFilter.val());
        filter();
    }

    function handleFilterFormSubmit(e) {
        e.preventDefault();

        let errors = $(e.target).find("span.error-message.displayed")
        if (Boolean(errors.length)) {
            alert("Invalid filters")
            return;
        }

        const formData = new FormData(e.target)
        FILTER_KEYS.forEach(filterKey => {
            if (filterKey.includes("date")) {
                queryParams.delete(`${filterKey}_from`);
                queryParams.delete(`${filterKey}_to`);
            } else {
                queryParams.delete(filterKey);
            }
        }) // remove previous key: value

        for (const [key, value] of formData) {
            if (["impact_score_cat", "screenshot_count_cat", "network_count_cat"].includes(key)) {
                if (formData.get(key.replace("_cat", "")) === "") continue;
            }
            if (value === "") continue;
            queryParams.append(key, value); // append new key: value
        }

        if (this.submitAction === "exportCSV") {
            queryParams.append("export_csv", true);
        } else {
            queryParams.delete("export_csv");
        }

        filter();
    }

    function setFilterCount () {
        if (!$workspaceFilterCount) return;
        let count = null;
        let countExemptedKeys = [
            "time_zone",
            "impact_score_cat",
            "screenshot_count_cat",
            "network_count_cat",
        ];

        FILTER_KEYS.filter(k => !countExemptedKeys.includes(k) ).forEach(filterKey => {
            if (filterKey.includes("date")) {
                if ((queryParams.has(`${filterKey}_from`) || queryParams.has(`${filterKey}_to`)) &&
                     (!!queryParams.get(`${filterKey}_from`) || !!queryParams.get(`${filterKey}_to`))) {
                    count = (count || 0) + 1;
                }
            } else {
                if (queryParams.has(filterKey)) count = (count || 0) + 1;
            }
        });
        if (count < 1) {
            $("#completed-tab-warning").removeClass("d-none");
        }

        $workspaceFilterCount.text(count);
    }

    function handleFilterClear () {
        FILTER_KEYS.forEach(filterKey => {
            if (filterKey.includes("date")) {
                queryParams.delete(`${filterKey}_from`);
                queryParams.delete(`${filterKey}_to`);
            }
            queryParams.delete(filterKey);
        });
        filter();
    }

    function filter() {
        if (queryParams.has("page")) {
            queryParams.delete("page"); // reset page when applying filter
        }
        queryParams.delete("search_terms");
        queryParams.sort() // sort query params to match with Rails
        window.location.replace(`${window.location.pathname}?${queryParams.toString()}`);
    }





    /// checkbox events

    if ($workspaceSelectAllCheckbox) {
        $workspaceSelectAllCheckbox.on("change", selectAllOnChange);
        $caseCheckboxes.each(function() {
            const $checkbox = $(this);
            $checkbox.on("change", function() {
                let checkedCases = getCheckedCases();
                $workspaceSelectAllCheckbox.prop("checked", !checkedCases.includes(0));

            });
        });
    }

    function selectAllOnChange(selectAllEvent) {
        $caseCheckboxes.prop("checked", selectAllEvent.target.checked);
    }

    $(".js-workspace-checkbox").on("change", function() {
        let checkedCases = getCheckedCases();
        if (checkedCases.includes(1)) {
            if ($bulkAssignButton) $bulkAssignButton.removeClass("d-none")
            if ($bulkGenerateButton) $bulkGenerateButton.removeClass("d-none")
            if ($bulkBuiltInNextButton) $bulkBuiltInNextButton.removeClass("d-none")
            $bulkUpdateStatusButton.removeClass("d-none")
        } else {
            if ($bulkAssignButton) $bulkAssignButton.addClass("d-none")
            if ($bulkGenerateButton) $bulkGenerateButton.addClass("d-none")
            if ($bulkBuiltInNextButton) $bulkBuiltInNextButton.addClass("d-none")
            $bulkUpdateStatusButton.addClass("d-none")
        }
    });

    function getCheckedCases() {
        let checkedCases = []
        Array.from($caseCheckboxes).forEach(function(el) {
            checkedCases.push(el.checked ? 1 : 0)
        })
        return checkedCases
    }

    /// Modal open events
    if (bulkGenerateModal) {
        bulkGenerateModal.addEventListener('show.bs.modal', function () {
            let selectedCases = $(".js-workspace-checkbox.js-select-individual:checked");
            let casesContainer = $("#js-bulk-generate-case-container");
            casesContainer.empty();

            selectedCases.each(function(i, el) {
                casesContainer.append(`
                    <li>
                        <span>${$(el).data("name")}</span>
                        <input type="hidden" name="case_ids[]" value="${$(el).data("id")}" />
                    </li>
                `);
            });
        });
    }

    if (bulkBuiltInNextModal) {
        bulkBuiltInNextModal.addEventListener('show.bs.modal', function () {
            let selectedCases = $(".js-workspace-checkbox.js-select-individual:checked");
            let casesContainer = $("#js-bulk-built-in-next-case-container");
            casesContainer.empty();

            selectedCases.each(function(i, el) {
                casesContainer.append(`<input type="hidden" name="case_ids[]" value="${$(el).data("id")}" />`);
            });
        });
    }

    if (bulkUpdateStatusModal) {
        bulkUpdateStatusModal.addEventListener('show.bs.modal', function () {
            let selectedCases = $(".js-workspace-checkbox.js-select-individual:checked");
            let casesContainer = $("#js-bulk-update-status-case-container");
            casesContainer.empty();

            selectedCases.each(function(i, el) {
                casesContainer.append(`<input type="hidden" name="case_ids[]" value="${$(el).data("id")}" />`);
            });
        });
    }

    //// date validations
    //// This is also used on admin reports filter
    ////  TODO: create a js service for this

    $(".js-workspace-daterange-filters").on("input change", (e) => validateFilterDateFrom(e.target.value, $(e.target)))

    function validateFilterDateFrom(date, $input) {
        if (!validateDateFormat(date, $input)) return;
        let filterKey = $input.attr("name");

        // validate max date except for due dates
        let selectedDate = moment(new Date(date));
        if (!filterKey.includes("due") && selectedDate.isAfter(dateToday)) {
            showError(`Cannot be greater than the date today, ${dateToday.format("MMMM DD, YYYY")}`, $input);
            return;
        }

        let $dateTo = $input.closest("div.js-workspace-date-filters").find("input.js-workspace-daterange-filters.js-to");
        if ($dateTo.val() === "") return;
        validateFilterDateTo($dateTo.val(), $dateTo);
    }

    function validateFilterDateTo(date, $input) {
        if (!validateDateFormat(date, $input)) return;

        let $dateFrom = $input.closest("div.js-workspace-date-filters").find("input.js-workspace-daterange-filters.js-from");
        if ($dateFrom.val() === "") return;

        let selectedDate = moment(new Date(date));
        let dateFromVal = moment(new Date($dateFrom.val()));
        if (selectedDate.isBefore(dateFromVal)) {
            showError(`Cannot be less than ${dateFromVal.format("MMMM DD, YYYY")}`, $input);
            return;
        }
    }

    function validateDateFormat(date, $input) {
        if (!FILTER_DATE_REGEX.test(date.toLowerCase()) && Boolean(date.length)) {
            showError("Invalid date format", $input);
            return false;
        } else {
            hideError($input);
            return  true;
        }
    }

    function hideError($input) {
        $input.siblings("span.error-message")
            .text("")
            .removeClass("displayed")
    }

    function showError(msg, $input) {
        $input.siblings("span.error-message")
            .text(msg)
            .addClass("displayed");
    }
});