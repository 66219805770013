import * as $ from 'jquery';
import moment from 'moment'
import { Modal } from 'bootstrap';

document.addEventListener('DOMContentLoaded', function() {

  // Sets the bundy clock current time based on user's timezone
  const now = new Date();
  setDateTime(now);

  // Format the time log records
  formatDate();
  formatTime();

  displayGreeting();

  $(".clock-in-form").on("submit", function() {
    $(".clock-in-btn").prop("disabled", true);
  });

  if ($("#session_timeout_modal")) {
    const timeoutModal = new Modal($('#session_timeout_modal'));
    timeoutModal.show();
  }

  // Datetime functions
  function setDateTime(date) {
    const currentTimeDisplay = $('#currentTime');

    const formattedTime = moment(date).format('hh:mm A');
    const formattedDate = moment(date).format('MMMM DD, YYYY (ddd)');
    const formattedTimezone = new Intl.DateTimeFormat(undefined, {
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                timeZoneName: 'short'
                              }).format(now).replace(/.* /,"");

    if (currentTimeDisplay.length) {
      currentTimeDisplay.text(`${formattedTime} (${formattedTimezone}) | ${formattedDate}`);
    }
  }

  function formatDate() {
    $('.date-display').each(function() {
      const loggedAt = $(this).data('logged-at');

      if (loggedAt) {
        const momentDate = moment(loggedAt);
        const formattedDateTime = momentDate.format('dddd, MMMM D, YYYY');
        $(this).text(formattedDateTime);
      }
    });
  }

  function formatTime() {
    $('.time-display').each(function() {
      const loggedAt = $(this).data('logged-at');
      const clockInTime = $(this).data('clock-in-time');

      if (loggedAt) {
        const momentDate = moment(loggedAt);
        const formattedDateTime = momentDate.format('hh:mm A');
        $(this).text(formattedDateTime);
      }

      if (clockInTime) {
        const momentDate = moment(clockInTime);
        const formattedDateTime = momentDate.format('hh:mm A');
        $(this).text(formattedDateTime);
      }
    });
  }

  function displayGreeting() {
    const greetingElement = $('#bundyClockGreeting');
    if (!greetingElement.length) return;

    const currentHour = moment().hour();
    let greeting = "";

    if (currentHour < 12) {
      greeting = "Good morning";
    } else if (currentHour < 18) {
      greeting = "Good afternoon";
    } else {
      greeting = "Good evening";
    }

    greetingElement.text(`${greeting}!`);
  }
});
